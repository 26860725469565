/* WEB home */
import $ from "jquery"
import "bootstrap"

import Rails from "rails-ujs"
import "@hotwired/turbo-rails"

import "core-js/stable"
import "regenerator-runtime/runtime"

/* eslint-disable import/first */
import "bootstrap-datepicker"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css"

/* eslint-disable import/first */
import "@nadinewest/common/stylesheets/stylequiz-images"

/*
 * jQuery plugins
 */
import "single-page-nav"

import Cookies from "js-cookie"
window.Cookies = Cookies

import sha256 from "js-sha256"
window.sha256 = sha256

// ActionCable channels
import "../../channels"

// Stimulus controllers
import "../../controllers/web"

// Making jQuery available globally to make it available in other packs and to not import it in every module
window.$ = window.jQuery = $

Rails.start() // Rails ujs

/**
 * Re-Load tooltips
 */
function loadTooltips() {
  $('[data-toggle="tooltip"]').tooltip()
}

/* Reloadable */

function runReloadables() {
  loadTooltips() // Bootstrap tooltips
}

// :before-stream-render fires before rendering a Turbo Stream page update.
$(document).on("turbo:before-stream-render", function() {
  // console.log("turbo:before-stream-render")

  setTimeout(() => runReloadables(), 1000)
})


$(document).on("turbo:load", function() {
  // console.log("turbo:load")

  runReloadables()

  // One Page Navigation Setup
  $("#navigation").singlePageNav({
    offset: $("#navbar").outerHeight(),
    filter: ":not(.external)",
    speed: 1000,
    threshold: 600,
    currentClass: "active",
    beforeStart: function () {
      $(".navbar-collapse-1").collapse("toggle")
      //console.log("beforeStart singlePageNav")
    },
    onComplete: function () {
      //console.log("onComplete singlePageNav")
    },
  })

  $(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
      $(".scroll-to-top").addClass("affix")
    } else {
      $(".scroll-to-top").removeClass("affix")
    }
  });

  // Smooth Hash Link Scroll
  $(".smooth-scroll").click(function () {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      let target = $(this.hash)
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]")
      if (target.length) {
        $("html,body").animate(
          {
            scrollTop: target.offset().top - 60,
          },
          600
        )
        return false
      }
    }
  })
})
